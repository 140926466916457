@font-face {
  font-family: "Circular Std";
  src: url(../../fonts/Circular/CircularStd-Black.otf);
}
.sidebar {
  position: absolute;
  width: 20%;
  float: left;
  top: 0;
  bottom: 0;
  background: linear-gradient(171.85deg, #081A49 13.76%, #000E8A 47.87%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sidebar_title {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: normal;
  font-size: 24px !important;
  line-height: 28px;
  color: #FFFFFF;
  margin: 0px;
}

.sidebar_break {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.sidebar_list {
  padding: 20px 40px !important;
}

.sidebar_list_title {
  font-family: 'Circular Std';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 38px !important;
  color: #FFFFFF;
  margin: 0px;
}

.sidebar_list_inactive {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 38px;
  color: rgba(255, 255, 255, 0.4);
}


.check_active {
  color: #FFFFFF;
}

.check_inactive {
  display: none;
}
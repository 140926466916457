.navbar {
  width: 100%;
  height: 70px;
  background: $default-color;

  .logo-img {
    padding: 10px 10px 10px 50px;
  }

  .nav-items {
    padding: 22px;
    font-family: $default-font;
  }

  .nav-item {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin: 3px 5px 0px 0px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
  }

  .active {
    color: #FFFFFF !important
  }

  .nav-menu {
    color: #FFFFFF;
    float: right;
  }
}

.balance-items {
  padding: 20px 50px;

  .balance-amount {
    font-family: $default-font;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  .balance-name {
    font-family: $default-font;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.5);
  }
}
.chart-title {
  font-family: $default-font !important;
  font-style: normal;
  font-weight: normal;
  font-size: 24px !important;
  line-height: 28px !important;
  margin-right: auto;
}

.bucket-card {
  padding: 15px 30px;

  .title {
    margin-bottom: 20px;
    font-family: $default-font;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 38px;
  }

  .card-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5)
  }

  .asset-name {
    font-family: $default-font;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
  }

  .asset-info {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5)
  }
}

.staking-card {
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 5px;
  border: 2px solid $default-color;
  padding: 20px;
  text-align: center;

  .title {
    font-family: $default-font;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }

  .card-logo {
    width: 50px;
    height: 50px;
    padding: 10px 0 5px 0;
  }

  .status-info {
    font-family: $default-font;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }

  .footer-info {
    margin-top: 10px;
    font-family: $default-font;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
  }
}

.custom-tooltip {
  background-color: white;
  padding: 5px 10px 20px 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
}
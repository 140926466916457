html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

header {
    height: 92px;
    margin-left: 20%;
    float: left;
    width: 100%;
    border-bottom: 1px solid #ECECEC;
}

.container {
    overflow: hidden;
    position: relative;
    min-height: 969px;
    height: 100%; /* <-- make the containing element full height */
}

.main {
    margin-left: 20% !important;
    float: left;
    padding: 30px;
    width: 80%;
}

.padding-40 {
    padding: 40px !important;
}

.text-danger {
    color: #E52005 !important;
}

.float-right {
    float: right !important;
}

.text-center {
    text-align: center !important;
}

.input-label {
    // font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #000000 !important;
}

.default-font {
    font-family: $default-font !important;
}

.btn-primary {
    border-radius: 300px;
    background: #F4F4F4;
    padding: 10px;
    // max-width: 150px;
    margin: 5px;
}
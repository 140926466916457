.header_title {
  padding: 10px 30px !important;
  font-family: $default-font !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 38px !important;
  text-align: center;
  width: 80% !important;
}

.page_title {
  font-family: $default-font !important;
  text-align: center;
  font-style: normal;
  font-weight: normal !important;
  font-size: 32px !important;
  line-height: 42px !important;
}

.logo-img {
  // width: 50px;
  // height: 50px;
  padding: 20px;
}

.submit-button {
  font-family: $default-font !important;
  padding: 10px !important;
  border-radius: 10px !important;
  background-color: $default-color !important;
  font-size: 20px !important;
  font-weight: normal !important;
  font-style: normal !important;
  line-height: 24px !important;
  text-transform: none !important;
}

.react-tel-input .form-control {
  // width: 100% !important;
  height: 50px !important;
  font-size: 20px !important;
}

.btn_add_bank {
  font-family: $default-font !important;
  padding: 6px 16px !important;
  border-radius: 10px !important;
  // transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // color: #fff;
  // background-color: $default-color !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.75;
  width: 500px;
  text-align: left;
  border: none !important;
  // text-transform: uppercase;
}

.persona-widget__iframe {
  width: 100% !important;
  height: 650px !important;
}

.survey_list_item {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 10px;

  &:hover {
    border: 2px solid $default-color;
  }

  .title {
    font-family: $default-font;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
  }

  .allocation {
    font-family: $default-font;
    font-style: italic;
    font-weight: 500;
    font-size: 10px;
    line-height: 30px;
  }
}

.survey_list_title {
  font-family: $default-font;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 50px !important;
  color: #000000;
}

.survey_list_info {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6);
}

.checkbox-button {
  margin: 10px 0px 10px 0px;
  width: 95%;
  border-radius: 5px;
  border: 1px solid #DBDBDBDB;
  box-sizing: border-box;

  &:hover {
    background: #DBDBDBDB;
  }

  input {
    float: right;
    margin: 10px;
    width: 22px;
    height: 22px;
    border-radius: 5px;
  }

  .round-checkbox {
    border-radius: 50%;
  }

  .p {
    margin: 0;
    display: block;
    border-radius: 5px;
  }

  .example-description {
    font-size: 10px;
    padding: 0 0 0 10px;
  }

  input:checked + div {
    border: 2px solid $default-color;
  }
}

.staking_list {
  border: 2px solid $default-color;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;

  .title {
    font-family: $default-font;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 40px;
  }

  .description {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
  }

  .list-title {
    font-size: 12px;
  }
}

.deposit-placeholder {
  font-family: $default-font !important;
  font-size: 64px !important;
  line-height: 28px !important;
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.1);
}

.deposit-description {
  font-family: $default-font !important;
  font-size: 16px !important;
  line-height: 28px !important;
  color: rgba(0, 0, 0, 0.5);
  text-align: center !important;
}

.percent-value {
  font-family: $default-font !important;
  font-size: 100px !important;
  color: #27AE60;
}

.percent-value-error {
  font-family: $default-font !important;
  font-size: 100px !important;
  color: #E52005;
}

.link_bank {
  border: 2px solid $default-color;;
  box-sizing: border-box;
  border-radius: 10px;

  // &:hover {
  //   border: 2px solid $default-color;
  // }

  .title {
    font-family: $default-font;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
  }
}